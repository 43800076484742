<template>
  <div class="school">
    <!--三项知识库-->
    <div class="zsk w-1400">
      <div class="zsk-title clearfix">
        <div class="fl"><i class="arrow-r"></i> 三农知识库</div>
        <!-- <div class="more fr">
          <router-link to="/List?infoType=259035353546821&type=三农知识库">
            更多<i class="el-icon-arrow-right"></i
          ></router-link>
        </div> -->
      </div>
      <div class="subtitle">
        <span>视频类：</span>
        <!-- <router-link to="/List?infoType=259035353546821&type=三农知识库"><span>更多</span></router-link> -->
      </div>
      <!-- <div class="zsk-mian">
        <el-row :gutter="30">
          <el-col :span="10">
            <div class="zsk-big">
              <div class="zsk-big-pic">
                <el-carousel
                  trigger="click"
                  height="440px"
                  arrow="never"
                  @change="calChange"
                >
                  <el-carousel-item
                    v-for="item in ZSKdata.slice(0, 3)"
                    :key="item.id"
                  >
                    <router-link
                      :to="`detail?id=${
                        item.id
                      }&type=三农知识库&oParams=${JSON.stringify(ZSKparams)}`"
                    >
                      <el-image
                        style="width: 100%; height: 100%"
                        :src="item.photoUrls"
                      ></el-image>
                    </router-link>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="zsk-big-info">
                <div class="zsk-big-h5 ellipsis">{{ ZSKdata[ZSKzct].title }}</div>
                <div class="zsk-big-text">
                  {{ regHtml(ZSKdata[ZSKzct].content) }}
                </div>
                <div class="zsk-big-time">
                  <span> {{ ZSKdata[ZSKzct].releaseDate }} </span>
                  <span> 浏览量：{{ ZSKdata[ZSKzct].viewNumber }} </span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="14">
            <div
              class="zsk-sm-item clearfix"
              v-for="item in ZSKdata.slice(3, 6)"
              :key="item.id"
            >
              <div class="zsk-sm-pic fl">
                <router-link
                  :to="`detail?id=${
                    item.id
                  }&type=三农知识库&oParams=${JSON.stringify(ZSKparams)}`"
                >
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls"
                  ></el-image>
                </router-link>
              </div>
              <div class="zsk-sm-info fr">
                <div class="zsk-sm-h5">{{ item.title }}</div>
                <div class="zsk-sm-text">{{ regHtml(item.content) }}</div>
                <div class="zsk-big-time">
                  <span> {{ item.releaseDate }} </span>
                  <span> 浏览量：{{ item.viewNumber }} </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div> -->
      <!-- <div class="subtitle">视频类：</div> -->
      <div class="zskvideo">
        <div class="zsk_tab">
          <div class="zsk_tab_item" :class="{act:item == videoparams1.filter.secondType}" v-for="item in zskVideoTab" :key="item" @click="handleTagChange(item)">{{item}}</div>
        </div> 
        <div class="zsk_main">
          <div class="zsk_list" v-if="zskVideo.length>0">
            <div class="zsk_item" v-for="item in zskVideo" :key="item.id">
              <div class="videoBox">
                <!-- <video :src="item.videoUrl" width="100%" height="100%" class="videotag" ></video> -->
                <el-image style="width: 100%; height: 100%" fit="cover" :src="item.photoUrls[0]"></el-image>
                <div class="vceng">
                  <div class="T_video" >
                      <img width="32px" :src="require('../../../public/img/video/play.png')" @click="onVideoPlay(`${item.videoUrl}`)" alt="">
                  </div>
                </div>
                
              </div>
              <div class="videoTitle ellipsis">{{item.videoName}} <span style="color:#999" v-if="item.source === '学习强国'">(学习强国外链)</span></div>              
            </div>
          </div>
          <div class="empty" v-else>
            <el-empty description="暂无数据"></el-empty>
          </div>
          <div style="text-align:center">
            <el-pagination background :page-size="5" :current-page="videoparams1.currentPage" layout="prev, pager, next" :total="videoTotal" @current-change="handleCurrentChange"></el-pagination>
          </div>
        </div>  
      </div>
      <div class="subtitle">资讯类：</div>
      <div class="zsk-mian">
        <!-- <el-row :gutter="30">
          <el-col :span="10">
            <div class="zsk-big">
              <div class="zsk-big-pic">
                <el-carousel
                  trigger="click"
                  height="440px"
                  arrow="never"
                  @change="calChange"
                >
                  <el-carousel-item
                    v-for="item in ZSKdata.slice(0, 3)"
                    :key="item.id"
                  >
                    <router-link
                      :to="`detail?id=${
                        item.id
                      }&type=三农知识库&oParams=${JSON.stringify(ZSKparams)}`"
                    >
                      <el-image
                        style="width: 100%; height: 100%"
                        :src="item.photoUrls"
                      ></el-image>
                    </router-link>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <div class="zsk-big-info">
                <div class="zsk-big-h5 ellipsis">{{ ZSKdata[ZSKzct].title }}</div>
                <div class="zsk-big-text">
                  {{ regHtml(ZSKdata[ZSKzct].content) }}
                </div>
                <div class="zsk-big-time">
                  <span> {{ ZSKdata[ZSKzct].releaseDate }} </span>
                  <span> 浏览量：{{ ZSKdata[ZSKzct].viewNumber }} </span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="14">
            <div
              class="zsk-sm-item clearfix"
              v-for="item in ZSKdata.slice(3, 6)"
              :key="item.id"
            >
              <div class="zsk-sm-pic fl">
                <router-link
                  :to="`detail?id=${
                    item.id
                  }&type=三农知识库&oParams=${JSON.stringify(ZSKparams)}`"
                >
                  <el-image
                    style="width: 100%; height: 100%"
                    :src="item.photoUrls"
                  ></el-image>
                </router-link>
              </div>
              <div class="zsk-sm-info fr">
                <div class="zsk-sm-h5">{{ item.title }}</div>
                <div class="zsk-sm-text">{{ regHtml(item.content) }}</div>
                <div class="zsk-big-time">
                  <span> {{ item.releaseDate }} </span>
                  <span> 浏览量：{{ item.viewNumber }} </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row> -->
        <div class="zxlist" v-for="row in zsksceondType" :key="row">
          <div class="zxzx-h5">
            <div>{{row}}</div>
            <div @click="$router.push('/list?infoType=259035353546821&type=三农知识库')">更多</div>
          </div>
          <div class="zxzx-list">
            <div class="zxzx-item" v-for="item in ZSKdata[row]" :key="item.id" @click="goZXKDetail(item.id,row)">
              <div class="zxzx-title ellipsis">{{item.title}}</div>
              <div class="zxzx-time">[{{item.releaseDate&&item.releaseDate.substr(0,10)}}]</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--专题讲座-->
    <div class="jz w-1400">
      <div class="zsk-title clearfix">
        <div class="fl"><i class="arrow-r"></i> 专题讲座</div>
        <div class="more fr">
          <!-- <router-link to="/chair"
            >更多<i class="el-icon-arrow-right"></i
          ></router-link> -->
        </div>
      </div>
      <div class="jz-main mt-20">
        <div class="subtitle">
          <span>政策理论学习</span>
          <router-link to="/List?infoType=259035797561413&type=政策理论学习&secondInfoType=政策理论学习"><span>更多</span></router-link>
        </div>
          <div class="zc-list">
            <div class="zc-item" v-for="item in zcData" :key="item.id" @click="$router.push(`detail?id=${item.id}&type=政策理论学习&oParams=${JSON.stringify(zcparams)}`)">
              <div class="imgbox">
                <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
              </div>
              <div class="zctitle ellipsis">{{item.title}}</div>
            </div>
          </div>
        <div class="subtitle">
          
          <div><img src="../../assets/img/hot-icon/ssgc.png" alt="" height="32px" style="vertical-align:middle;transform: translateY(-3px);"> 周末大讲堂</div>
          <!-- <router-link to="/videoList?type=周末大讲堂"><span>更多</span></router-link> -->
        </div>
        <div class="thirdH4">
          <div>资讯类：</div>
          <div @click="$router.push('/List?infoType=259035797561413&type=周末大讲堂&secondInfoType=周末大讲堂')">更多</div>
        </div>
        <div class="weekbox">
          <div class="zc-list">
            <div class="zc-item" v-for="item in weekData" :key="item.id" @click="$router.push(`detail?id=${item.id}&type=周末大讲堂&oParams=${JSON.stringify(weekparams)}`)">
              <div class="imgbox">
                <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
              </div>
              <div class="zctitle ellipsis">{{item.title}}</div>
            </div>
          </div>
        </div>
        <div class="thirdH4">
          <div>视频类：</div>
          <div @click="$router.push('/videoList?type=周末大讲堂')">更多</div>
        </div>
        <div class="carousel-box">
          <div class="w-box">
            <ul
              class="carousel-inner clearfix"
              :style="`width:${
                carData.length * 280
              }px;transform:translateX(${translateNum}px)`"
            >
              <li
                class="carousel-item fl"
                v-for="(item, index) in carData"
                :key="index + 'ii'"
              >
                <div class="carousel-item-pic">
                  <!-- <el-image
                    style="width: 100%; height: 100%"
                    :src="require('../../../public/img/sxxy/jz1.png')"
                  ></el-image>-->

                   <!-- <video :src="baseUrl + item.videoUrl"></video> -->
                  <!-- <video :src="item.videoUrl" style="width:100%;height:100%"></video> -->
                  <el-image style="width: 100%; height: 100%" fit="cover" :src="item.photoUrls[0]"></el-image>
                  
                  <div class="T_video" >
                      <img width="32px" :src="require('../../../public/img/video/play.png')" @click="onVideoPlay(`${item.videoUrl}`)" alt="">
                  </div>
                </div> 
                <div class="carousel-item-info ellipsis">
                  {{item.videoName}}
                </div>
              </li>
            </ul>
          </div>
          <div
            class="btn-prev"
            @click="prev"
            :class="{ disabled: translateNum == 0 }"
          >
            <i class="el-icon-arrow-left"></i>
          </div>
          <div
            class="btn-next"
            @click="next"
            :class="{ disabled: (translateNum == carData.length * -280 + 1120)  || (carData.length <=4)}"
          >
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <!--三农专家-->
    <div class="zj w-1400">
      <div class="zsk-title clearfix">
        <div class="fl"><i class="arrow-r"></i> 三农专家</div>
        <!-- <router-link :to="`/Personnelfile`">
          <div class="more fr">更多<i class="el-icon-arrow-right"></i></div>
        </router-link> -->
      </div>
      <div class="zj-main">
        <el-row :gutter="30">
          <el-col :span="16">
            <el-row :gutter="30">
              <div class="zjfl">
                <h5>专家分类：</h5>
                <div class="zjfl-item" v-for="item in zjflList" :key="item" @click="pageParamsChage(item)" :class="{act:params.filter.type == item}">{{item}}</div>
              </div>
              <el-col :span="12" v-for="item in pageData.list" :key="item.id + 'zj'">
                <div class="zj-item clearfix">
                  <router-link :to="`persondetail?id=${item.id}`">
                    <div class="fl zj-pic">
                      <el-image
                        style="width: 100%; height: 100%"
                        :src="item.photoUrls"
                      ></el-image>
                    </div>
                    <div class="fr zj-info">
                      <div class="zj-h4">
                        <span class="zj-name">{{ item.name }}</span>
                        <span class="zj-position">{{ item.type }}</span>
                      </div>
                      <div class="zj-intro">
                        {{ item.honorInfo }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </el-col> 
            </el-row>
            <div style="margin-top:12px;text-align:center">
                <el-pagination background :current-page="params.currentPage" layout="prev, pager, next" :total="pageData.total" @current-change="HandelPage"></el-pagination>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="zj-hot">
              <div class="zj-hot-h4">热门专家</div>
              <div
                class="zj-hot-item clearfix"
                v-for="item in pageDatarecommend"
                :key="item.id + 'hot'"
              >
                <router-link :to="`persondetail?id=${item.id}`">
                  <div class="fl zj-pic">
                    <el-image
                      style="width: 100%; height: 100%"
                      :src="item.photoUrls"
                    ></el-image>
                  </div>
                  <div class="fr zj-info">
                    <div class="zj-h4">
                      <span class="zj-name">{{ item.name }}</span>
                    </div>
                    <div class="zj-intro">
                      {{ item.jobInfo }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
      <!--视频播放窗口-->
    <el-dialog title="" :visible.sync="dialogVideo" width="840px" class="videodlog" append-to-body @close="onVideoClear">
      <i class="el-icon-close icon_btn" @click="dialogVideo = false" style="background:transparent;position:absolute;top:5px;right:10px;font-size:24px;z-index:99999;color:#fff"></i>
      <div class="videoplayer" v-if="dialogVideo">
        <video-player class="bideo-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
      </div>
      <div class="video_List clearfix">
        <ul>
          <li v-for="item in videoList" class="fl" :key="item+'_key'" style="margin-left:15px">
            <div class="video_box">
              <video :src="item" style="width:100%;height:100%"></video>
              <div class="video_shade"><img :src="require('../../../public/img/video/play.png')" @click="onVideoPlay(item)"></div>
            </div>
            <div class="video_title">{{item.substr(item.lastIndexOf('\\')+1)}}</div>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { regHtml } from "@/utils/reg";
import { GetList } from "@/api/other.js";
import { GetPersonnelfilePage, GetVideoInfoPage } from "@/api/xiangningqing";
export default {
  data() {
    return {
      // carData: [{}, {}, {}, {}, {}],
      carData:[],
      translateNum: 0,
      zsksceondType:['种植技术','养殖技术','农牧机械','高效畜牧业'],
      ZSKparams: {
        //三农知识库
        currentPage: 1,
        filter: { infoType: 259035353546821 },
        pageSize: 11,
      },
      zcparams: {
        //政策理论学习
        currentPage: 1,
        filter: { infoType: 259035797561413,secondInfoType:'政策理论学习'},
        pageSize: 5,
      },
      weekparams: {
        //政策理论学习
        currentPage: 1,
        filter: { infoType: 259035797561413,secondInfoType:'周末大讲堂'},
        pageSize: 5,
      },
      zskVideoTab:['设施蔬菜栽培学','农技知识','植物营养学','土壤和肥料学','农业机械学','高效畜牧业'],
      pageData: [],
      pageDatarecommend: {},
      ZSKdata: {},
      zcData:[],//政策学习
      dialogVideo: false,
      videoList: [],//多个视频列表
      playerOptions: {},
      ZSKzct: 0,
      zskVideo:[],
      params: {
        currentPage: 1,
        pageSize: 10,
        filter: {
          type: "全部",
        },
      },
      videoparams: {
        currentPage: 1,
        pageSize: 5,
        filter: {
          type: "专题讲座",
          infoType: 256581677129797,
        },
      },
      videoTotal:0,
      videoparams1: {
        currentPage: 1,
        pageSize: 5,
        filter: {
          type: "三农知识库",
          secondType:"设施蔬菜栽培学"
        },
      },
      zjflList:['全部','种植专家','经营专家','养殖专家','兽医专家'],
      weekData:[],
    };
  },
  computed: {
    baseUrl: () => process.env.VUE_APP_PHOTO_URL,
    regHtml: () => regHtml,
  },
  methods: {
    goZXKDetail(id,key){
      const params = {
        currentPage: 1,
        filter: { infoType: 259035353546821 ,secondInfoType:key},
        pageSize: 10,
      }
      this.$router.push(`/detail?id=${id}&type=三农知识库&oParams=${JSON.stringify(params)}`)
    },
    handleTagChange(key){
      this.videoparams1.filter.secondType = key;
      this.videoparams1.currentPage = 1;
      this.GetVideoList1();
    },
    handleCurrentChange(page){
      this.videoparams1.currentPage = page;
      this.GetVideoList1()
    },
    prev() {
      if (this.translateNum == 0) return false;
      this.translateNum += 280;
    },
    next() {
      if (this.translateNum == this.carData.length * -280 + 1120) return false;
      if(this.carData.length<=4) return false;
      console.log(this.translateNum);
      this.translateNum -= 280;
    },
    calChange(a) {
      this.ZSKzct = a;
    },
    GetVideoList() {//专题讲座视频
      GetVideoInfoPage(this.videoparams).then((res) => {
        const data = res.data.data.list;
        data.forEach((d) => {
          d.photoUrls = d.photoUrls.split(",");
        });
        this.carData = data;
      });
    },
    GetVideoList1() {//三农知识库视频
      GetVideoInfoPage(this.videoparams1).then((res) => {
        const data = res.data.data.list;
        data.forEach((d) => {
          d.photoUrls = d.photoUrls.split(",");
        });
        this.videoTotal = res.data.data.total;
        this.zskVideo = data;
      });
    },

    getZSKdata(key) {
      const params ={
        currentPage: 1,
        filter: { infoType: 259035353546821 ,secondInfoType:key},
        pageSize: 10,
      }
      GetList(params).then((res) => {
        this.ZSKdata[key] = res.data.data.list;
      });
    },
    getZCdata(){
      GetList(this.zcparams).then((res) => {
        this.zcData = res.data.data.list;
      });
    },
    getWeekdata(){
      GetList(this.weekparams).then((res) => {
        this.weekData = res.data.data.list;
      });
    },
    pageParamsChage(row){
      this.params.filter.type = row;
      this.params.currentPage = 1;
      this.getPersonnelfileList();
    },
    HandelPage(a){
      this.params.currentPage = a;
      this.getPersonnelfileList();
    },
    getPersonnelfileList() {
      const params = JSON.parse(JSON.stringify(this.params))
      if(params.filter.type == '全部')params.filter.type = ''
      GetPersonnelfilePage(params).then((res) => {
        this.pageData = res.data.data;
      });
    },
    getPersonnelfilerecommend() {
      const reparams = { currentPage: 1, pageSize: 3, filter: {} };
      GetPersonnelfilePage(reparams).then((res) => {
        this.pageDatarecommend = res.data.data.list;
      });
    },
    onVideoClear(){
      this.videoList = [];
    },
    onVideoPlay(path){
      let _src = Array.isArray(path)?path[0]:path;
      if(_src.indexOf('http')>-1){
        window.open(_src);
        return
      }
      let option = {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: _src // url地址
          },
        ],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
      if(Array.isArray(path)&&path.length>1){this.videoList = path};
      this.playerOptions = option;
      this.dialogVideo = true;
    },
  },
  mounted() {
    // this.getZSKdata();
    this.zsksceondType.forEach(item =>{
      this.getZSKdata(item)
    })
    this.getPersonnelfileList();
    this.getPersonnelfilerecommend();
    this.GetVideoList();
    this.GetVideoList1();
    this.getZCdata();
    this.getWeekdata();
  },
};
</script>
<style scoped>
.thirdH4{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  border-bottom: 1px solid #ccc;
  padding: 0 10px;
  margin-bottom: 10px;
}
.thirdH4>div:last-child{
  cursor: pointer;
}
.zjfl{
  display: flex;
  font-size: 16px;
  color: #666;
  padding-left: 42px;
  line-height: 32px;
}
.zjfl-item{
  margin-right: 20px;
  background: rgba(53, 148, 236,.3);
  color: #333;
  padding: 0 12px;
  cursor: pointer;
}
.zjfl-item.act{
  background: #1c91ff;
  color: #fff;
}
.zsk-mian{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.zxlist{
  width: 48%;
  /* margin-top: 20px; */
  border: 2px solid #f1f1f1;
  margin-bottom: 16px;
}
.zxzx-h5{
  font-size: 18px;
  color: #666;
  background: #f1f1f1;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
}
.zxzx-h5>div:first-child{
  font-weight: bold;
  color: #666;
  padding: 0 20px;
  background: #fff;
  border-radius: 0 32px 0 0;
}
.zxzx-h5>:last-child{
  margin-right: 12px;
  font-size: 16px;
}
.zxzx-h5>:last-child:hover{
  color: #888;
  cursor: pointer;
}
.zxzx-item{
  display: flex;
  justify-content: space-between;
  font-size:16px;
  line-height: 30px;
}
.zxzx-item:hover .zxzx-title,.zxzx-item:hover .zxzx-time{
  color: #1c91ff;
  cursor: pointer;
}
.zxzx-list{
  padding: 12px;
}
.zxzx-title{
  width: 80%;
  color: #333;
}
.zxzx-time{
  width: 20%;
  text-align: center;
  color: #777;
}
.zc-list{
  display: flex;
}
.zc-item{
  width: 20%;
  padding: 10px;
  cursor: pointer;
}
.imgbox{
  height: 220px;
}
.zctitle{
  line-height: 40px;
  font-size: 14px;
  background: #e7e7e7;
  text-align: center;
  padding: 0 5px;
}
.videoTitle{
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  background: #e7e7e7;
}
.videoBox{
  width: 100%;
  height: 180px;
  position: relative;
}
.vceng{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .3);
  
}
.zsk_list{
  display: flex;
}
.zsk_item{
  width: 20%;
  padding: 10px;
  height: 260px;
}
.zsk_tab{
  display: flex;
  justify-content: center;
}
.zsk_tab_item{
  font-size: 14px;
  padding: 0 20px;
  cursor: pointer;
  margin: 10px 0;
}
.zsk_tab_item~.zsk_tab_item{
  border-left: 1px solid #ccc;
}
.zsk_tab_item:hover,.zsk_tab_item.act{
  color: #1c91ff;
}
.subtitle{
  line-height: 30px;
  font-weight: bold;
  font-size: 16px;
  color: #1170c9;
  line-height: 42px;
  background: #eee;
  margin: 16px 0; 
  padding:0  12px;
  display: flex;
  justify-content: space-between;
}
.T_video{
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.zj-hot-item {
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e7ed;
  margin-top: 15px;
}
.zj-hot-item:last-child {
  border-width: 0;
}
.zj-hot-h4 {
  font-size: 18px;
  font-weight: 600;
}
.zj-hot {
  border: 1px solid #e4e7ed;
  padding: 10px 15px;
  margin-top: 15px;
}
.zj-intro {
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.zj-name {
  font-size: 18px;
  font-weight: 600;
}
.zj-position {
  font-size: 16px;
  margin-left: 30px;
}
.zj-h4 {
  padding-top: 10px;
  height: 40px;
}
.zj-info {
  width: calc(100% - 130px);
  height: 100px;
  overflow: hidden;
}
.zj-pic {
  width: 100px;
  height: 120px;
}
.zj-item:hover,
.zj-item.act {
  background: #0169ca;
}
.zj-item.act * ,.zj-item:hover * {
  color: #fff;

}
.zj-item {
  padding: 15px;
  margin-top: 15px;
  background: #eee;
}
.btn-prev {
  left: 0;
}
.btn-next {
  right: 0;
}
.btn-prev.disabled,
.btn-prev.disabled:hover,
.btn-next.disabled,
.btn-next.disabled:hover {
  color: #ccc;
}
.btn-prev:hover,
.btn-next:hover {
  color: #1c91ff;
  background: #f1f1f1;
}
.btn-prev,
.btn-next {
  width: 40px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  font-size: 40px;
  color: #0169ca;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #eee;
  cursor: pointer;
}
.carousel-inner {
  width: 1800px;
  transition: transform 1.5s;
}
.w-box {
  width: 1090px;
  margin: 0 auto;
  overflow: hidden;
}
.carousel-item-info {
  line-height: 50px;
  font-size: 20px;
  text-align: center;
  padding: 0 8px;
}
.carousel-item-pic {
  width: 250px;
  height: 210px;
  position: relative;
}
.carousel-item:hover {
  /* border: 1px solid #777; */
}
.carousel-item:hover .carousel-item-info {
  background: #f37b1c;
  color: #fff;
}
.carousel-item {
  width: 250px;
  height: 260px;
  margin-right: 30px;
  background: #eee;
  box-sizing: border-box;
}
.carousel-box {
  height: 260px;
  width: 1200px;
  overflow: hidden;
  position: relative;
}
.zsk-sm-text {
  line-height: 30px;
  font-size: 16px;
  text-indent: 30px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 90px;
}
.zsk-sm-h5 {
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
}
.zsk-sm-info {
  width: calc(100% - 280px);
}
.zsk-sm-pic {
  width: 260px;
  height: 170px;
}
.zsk-sm-item ~ .zsk-sm-item {
  margin-top: 15px;
}
.zsk-sm-item {
  height: 190px;
  overflow: hidden;
}
.zsk-sm-item {
  padding: 10px;
  border: 1px solid #e4e7ed;
}
.zsk-big-time span:last-child {
  margin-left: 30px;
}
.zsk-big-time {
  font-size: 16px;
  color: #666;
  text-align: right;
  line-height: 30px;
}
.zsk-big-text {
  line-height: 30px;
  font-size: 16px;
  text-indent: 30px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 60px;
}
.zsk-big-h5 {
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
}
.zsk-big-info {
  padding: 15px;
}
.zsk-big {
  border: 1px solid #e4e7ed;
  height: 600px;
}
.zsk-big-pic {
  height: 440px;
}
.zsk-title .arrow-r {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/img/right2.png) no-repeat;
  background-size: 100% 100%;
  transform: translateY(2px);
}
.zsk-title {
  height: 42px;
  line-height: 42px;
  font-size: 20px;
  font-weight: 600;
  /* border-bottom: 1px solid #999; */
}
.school {
  font-family: "微软雅黑";
}
</style>